import React, { useState } from "react";
import Loader from "./Loader";
import Scrollontop from "./Scrollontop";

const Header = React.lazy(() => import("./Header"));
const About = React.lazy(() => import("./About"));
const HeroSection = React.lazy(() => import("./HeroSection"));
const Skills = React.lazy(() => import("./Experience"));
const Projects = React.lazy(() => import("./Projects"));
const Resume = React.lazy(() => import("./Resume"));
const Footer2 = React.lazy(() => import("./Footer2"));
const TechStacks = React.lazy(() => import("./TechStacks"));

const Home = () => {
  const [showLoader, setShowLoader] = useState(true);

  const handleAnimationComplete = () => {
    setShowLoader(false);
  };

  return (
    <div className="smooth-wrapper relative">
      {showLoader ? (
        <Loader onAnimationComplete={handleAnimationComplete} />
      ) : (
      
          <div className="smooth-content">
            <Scrollontop />
            <Header />
            <div className="">
              <HeroSection />
            </div>
            <div className="md:p-8 px-2 h-auto">
              <About />
            </div>
            <div className="m-auto">
              <Resume />
            </div>
            <Skills />
            <TechStacks />
            <Projects />
            <Footer2 />
          </div>
       
      )}
    </div>
  );
};

export default Home;
