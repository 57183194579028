import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { motion } from "framer-motion";

const Loader = ({ onAnimationComplete }) => {
  const loaderRef = useRef();
  const backgroundRef = useRef();
  const textRefs = useRef([]);
  const dotRefs = useRef([]);

  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: onAnimationComplete,
    });

    // Animated text reveal
    textRefs.current.forEach((text, index) => {
      tl.fromTo(
        text,
        { 
          y: 50, 
          opacity: 0, 
          rotateX: -90 
        },
        { 
          y: 0, 
          opacity: 1, 
          rotateX: 0,
          duration: 0.8, 
          ease: "back.out(1.7)",
          delay: index * 0.2
        }
      );
    });

    // Background slide and scale
    tl.fromTo(
      backgroundRef.current,
      { 
        scale: 1.2, 
        opacity: 0 
      },
      { 
        scale: 1, 
        opacity: 1, 
        duration: 1, 
        ease: "power2.inOut" 
      },
      "-=0.5"
    );

    // Dot animation
    dotRefs.current.forEach((dot, index) => {
      tl.fromTo(
        dot,
        { 
          scale: 0, 
          opacity: 0 
        },
        { 
          scale: 1, 
          opacity: 1, 
          duration: 0.5, 
          ease: "elastic.out(1, 0.3)",
          stagger: 0.1
        },
        "-=0.3"
      );
    });

    // Final loader exit
    tl.to(loaderRef.current, {
      yPercent: -100,
      duration: 1,
      ease: "power2.inOut",
      delay: 0.5
    });
  }, [onAnimationComplete]);

  return (
    <div 
      ref={loaderRef} 
      className="fixed inset-0 z-50 flex flex-col items-center justify-center 
                 bg-gradient-to-br from-gray-900 via-gray-800 to-black 
                 text-white overflow-hidden"
    >
      <div 
        ref={backgroundRef} 
        className="absolute inset-0 bg-cover bg-center opacity-0"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), 
                            url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Cg fill-rule="evenodd"%3E%3Cg fill="%23dddddd" fill-opacity="0.1"%3E%3Cpath opacity=".5" d="M96 95h4v1h-4v4h-1v-4h-9v-1h9v-9h1v9h9v1h-9v9h-1v-9zM16 32h4v1h-4v4h-1v-4h-9v-1h9v-9h1v9h9v1h-9v9h-1v-9zm-5 0h1v1h-1v1h-1v-1h1v-1zm24-13h4v1h-4v4h-1v-4h-9v-1h9v-9h1v9h9v1h-9v9h-1v-9zm-5 0h1v1h-1v1h-1v-1h1v-1z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E')`
        }}
      />
      
      <div className="relative z-10 text-center">
        <div className="mb-8 space-y-2">
          <h5 
            ref={(el) => textRefs.current[0] = el}
            className="text-xl font-light tracking-widest opacity-0"
          >
            Design Portfolio
          </h5>
          <h5 
            ref={(el) => textRefs.current[1] = el}
            className="text-sm text-gray-400 opacity-0"
          >
            &copy; 2024 Animish Chopade
          </h5>
        </div>

        <h1 
          ref={(el) => textRefs.current[2] = el}
          className="text-5xl font-bold tracking-tight opacity-0"
        >
          Welcome to My <span className="text-cyan-400">Portfolio</span>
        </h1>
      </div>

      <div className="flex space-x-3 mt-12">
        {[0, 1, 2].map((index) => (
          <motion.div
            key={index}
            ref={(el) => dotRefs.current[index] = el}
            className="w-4 h-4 bg-cyan-500 rounded-full opacity-0"
            animate={{
              scale: [1, 1.5, 1],
              transition: {
                duration: 1.5,
                repeat: Infinity,
                delay: index * 0.2
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Loader;